
import { Component, namespace } from 'nuxt-property-decorator'
import LayoutDefault from '@Core/layouts/default.vue'
import { Route } from 'vue-router'

const instances = namespace('instances')

@Component({})
export default class LayoutTicketing extends LayoutDefault {
  @instances.State
  instance

  get breadcrumbs(): { title: string; href: Route }[] {
    if (process.server) {
      return []
    }
    const crumbs: { title: string; href: Route }[] = []

    crumbs.push({
      title: 'Events',
      href: {
        fullPath: `${this.$config.get('URLS').whats_on}`,
      },
    })

    if (this.instance && this.instance.event) {
      const title = this.instance.title

      crumbs.push({
        title,
        href: {
          fullPath: `${this.$config.get('URLS').event}${
            this.instance.event.alias
          }`,
        },
      })
    }

    return crumbs || []
  }
}
