import Vue from 'vue'
import { MutationTree } from 'vuex'
import {
  Zone,
  ZonePrice,
  Screen,
  ScreenZone,
  Seat,
  SeatType,
  SeatMap,
} from '@Core/@types/skyway'
import { BasketSeat } from '@Basket/store/basket/state'
import { types } from './types'
import { State, Recipient } from './state'

type ZonePriceTransformed = ZonePrice & {
  access: boolean
  available: boolean
  zone_refs: string[] | number[]
  color: number
  price_letter: string
}

const mutations: MutationTree<State> = {
  [types.SET_MAP_META](state: State, payload: SeatMap): void {
    if (payload.seatmap_ref) {
      state.seat_map_ref = payload.seatmap_ref
    }
    if (payload.seatmap_image) {
      state.seat_map_svg = payload.seatmap_image
    }
    if (payload.title) {
      state.seat_map_title = payload.title
    }
  },
  [types.SET_MAP_KEY](state: State, payload: string): void {
    state.key = payload
  },
  [types.SET_MAP_SVG](state: State, payload: { seatmap_image: string }): void {
    state.seat_map_svg = payload.seatmap_image
  },
  [types.SET_SCREENS](state: State, payload: { screens: Screen[] }): void {
    state.screens = payload.screens
  },
  [types.SET_SCREEN_META](state: State, payload: { screens: Screen[] }): void {
    state.screen_meta = payload.screens
  },
  [types.SET_ZONES](state: State, payload: any): void {
    state.zones = payload.zones || []
  },
  [types.SET_CHOSEN_SCREEN](state: State, payload: string): void {
    state.chosen_screen = payload
  },
  [types.SET_CHOSEN_SCREEN_ZONE](state: State, payload: ScreenZone): void {
    state.chosen_screen_zone = payload
  },
  [types.SET_CHOSEN_ZONE](state: State, payload: string): void {
    state.chosen_zone = payload
  },
  [types.SET_AVAILABILITY](state: State, payload: Seat[]): void {
    state.available_seats = payload
  },
  [types.SET_UPDATING](state: State, payload: boolean): void {
    state.updating = payload
  },
  [types.SET_SCREEN_AVAILABILITY](state: State, payload): void {
    state.screen_availability = payload
  },
  [types.SET_CONSECUTIVE_AVAILABILTY](state: State, payload: boolean): void {
    state.no_consecutive_availability = payload
  },
  [types.SET_SEATS](state: State, payload: SeatMap): void {
    if (payload.screens) {
      for (const scr of payload.screens) {
        if (scr && scr.seats) {
          // @ts-ignore
          state.seats.push(...scr.seats)
        }
      }
    }
  },

  /**
   * Set the best price for each zone
   *
   * we sort the array of prices so that those marked as best are first
   */
  [types.SET_BEST_PRICES](state: State, payload: { zones: Zone[] }): void {
    if (!payload.zones) return

    const bestPrices: any[] = []

    // get a list of prices that have availability and are enabled
    for (const zone of payload.zones) {
      if (zone.availability && zone.availability > 0 && zone.prices) {
        for (const price of zone.prices) {
          if (price && price.enabled) {
            bestPrices.push(price)
          }
        }
      }
    }

    // sort the prices
    const sorted = bestPrices.sort((a, b) => {
      if (a.price > b.price) return -1
      if (a.price < b.price) return 1
      return 0
    })

    state.best_prices = sorted
  },

  /**
   * Get a list of unique prices by monetary value
   */
  [types.SET_UNIQUE_PRICES](state: State, payload: { zones: Zone[] }): void {
    if (!payload.zones) return

    const prices: ZonePriceTransformed[] = []

    /**
     * Function returns an enabled price given a particular zone
     * it will return the best price first if it exists, then the base
     * price if exists.  If neither exist, then it will return
     * the first enabled price for that zone
     */
    function findBestPrice(zone: Zone): ZonePriceTransformed | undefined {
      let price: ZonePriceTransformed | undefined
      price = (zone.prices as ZonePriceTransformed[])?.find(
        (price) => price?.enabled && (price.best || price.base)
      )
      if (!price) {
        price = (zone.prices as ZonePriceTransformed[]).filter(
          (p) => p.enabled && !p.zone_title?.includes('Wheelchair')
        )?.[0]
      }

      return price
    }

    payload.zones
      .filter(
        (zone) =>
          Number(zone.availability) > 0 && !zone.title?.includes('Standing')
      )
      .forEach((zone) => {
        const price: ZonePriceTransformed | undefined = findBestPrice(zone)

        if (price) {
          const existingPrice: ZonePriceTransformed | undefined = prices.find(
            (p) => p.price === price.price
          )

          if (!existingPrice) {
            price.access = false
            price.available = Boolean(zone && (zone.availability as number) > 0)
            price.zone_refs = [zone.zone_ref]
            price.zone_ref = zone.zone_ref
            prices.push(price)
          } else {
            if (!existingPrice.available) {
              existingPrice.available = Boolean(
                zone && (zone.availability as number) > 0
              )
            }
            existingPrice.zone_refs.push(zone.zone_ref)
          }
        }
      })

    prices.sort((a, b) => (b.price || 0) - (a.price || 0))

    let increment = 0
    prices.forEach((price) => {
      if (!price.zone_title?.includes('Wheelchair')) {
        price.color = increment
        price.price_letter = String.fromCharCode(increment + 65)
        increment++
      }
    })

    state.unique_prices = prices
  },

  [types.SET_SCREEN_ZONES](state: State, payload: ScreenZone[]): void {
    state.screen_zones = payload
  },
  [types.SET_SEAT_TYPES](state: State, payload: SeatType[]): void {
    state.types = payload
  },

  [types.UPDATE_PRICE_FILTERS](state: State, payload: any): void {
    const index = state.filters.findIndex((ref) => ref === payload)

    if (index === -1) {
      state.filters.push(payload)
    } else {
      state.filters.splice(index, 1)
    }
  },
  [types.UPDATE_ACCESS_FILTERS](state: State, payload: any[]): void {
    state.access_filters = payload
  },
  [types.SET_ACTIVE](state: State, payload: { seat: Seat; coords: any }): void {
    state.active = payload
  },
  [types.CLEAR_ACTIVE](state: State): void {
    state.active = null
  },
  [types.CLEAR_SELECTION](state: State) {
    state.selection = []
  },
  [types.SET_SELECTION](
    state: State,
    payload: { seat: Seat; price_type_ref: number }
  ) {
    if (payload.seat && state.selection) {
      const index = state.selection.findIndex(
        (seat) =>
          seat.seat &&
          parseInt(seat.seat.seat_ref) === parseInt(payload.seat.seat_ref)
      )

      if (index === -1) {
        state.selection.push(payload)

        const seatMap =
          (state.chosen_screen && state.maps[state.chosen_screen]) || null

        if (seatMap && state.chosen_screen) {
          const data = seatMap.map((seat) => {
            return {
              ...seat,
              selected: payload.seat.seat_ref === seat.seat_ref,
            }
          })

          Vue.set(state.maps, state.chosen_screen, data)
        }
      }
    } else if (!payload) {
      Vue.set(state, 'selection', [])
    }
  },
  [types.REMOVE_SELECTION](state: State, payload: Seat) {
    const index = state.selection.findIndex(
      (seat: { seat: Seat }) =>
        seat.seat.seat_ref &&
        parseInt(seat.seat.seat_ref) === parseInt(payload.seat_ref)
    )

    if (index !== -1) {
      state.selection.splice(index, 1)

      const seatMap =
        (state.chosen_screen && state.maps[state.chosen_screen]) || null

      if (seatMap && state.chosen_screen) {
        const data = seatMap.map((seat) => {
          return {
            ...seat,
            selected:
              payload.seat_ref === seat.seat_ref
                ? !seat.selected
                : seat.selected,
          }
        })

        Vue.set(state.maps, state.chosen_screen, data)
      }
    }
  },
  [types.SET_BEST_AVAILABLE_ZONE](state: State, payload: Zone) {
    state.best_available_zone = payload
    state.chosen_zone = payload.zone_ref
  },
  [types.SET_BEST_AVAILABLE_SELECTION](state: State, payload: any) {
    if (state.best_available_selection) {
      const index = state.best_available_selection.findIndex(
        (price) =>
          parseInt(price.price_type_ref) === parseInt(payload.price_type_ref)
      )

      if (index !== -1) {
        if (payload.qty === 0 || isNaN(payload.price_type_ref)) {
          state.best_available_selection.splice(index, 1)
        } else {
          Vue.set(state.best_available_selection, index, payload)
        }
      } else if (payload.qty > 0 && !isNaN(payload.price_type_ref)) {
        state.best_available_selection.push(payload)
      }
    }
  },
  [types.CLEAR_BEST_AVAILABLE_SELECTION](state: State) {
    Vue.set(state, 'best_available_selection', [])
  },
  [types.CLEAR_RECIPIENTS](state: State) {
    state.recipients = {}
  },
  [types.CLEAR_INSTANCE_RECIPIENTS](state: State, payload: string) {
    state.recipients[payload] = []
  },
  [types.SET_RECIPIENTS](state: State, payload: any) {
    state.recipients = payload
  },
  [types.SET_RECIPIENT](state: State, payload: any) {
    if (payload && state.recipients) {
      if (!state.recipients[payload.instance_ref]) {
        Vue.set(state.recipients, payload.instance_ref, [])
      }
      const index = state.recipients[payload.instance_ref].findIndex(
        (r) => r.email && r.email === payload.recipient.email
      )

      if (index === -1) {
        state.recipients[payload.instance_ref].push(payload)
      }
    }
  },
  [types.REMOVE_RECIPIENT](state: State, payload: Recipient) {
    state.recipients.splice(
      state.recipients.findIndex((r) => r.email && r.email === payload.email),
      1
    )
  },
  [types.TOGGLE_WHEELCHAIR](state: State, payload: boolean) {
    state.wheelchair = payload
  },
  [types.SET_GENERAL_ADMISSION_ZONES](state: State, payload: ZonePrice[]) {
    state.general_admission_zones = payload
  },
}

export default mutations
