import {
  Screen,
  ScreenZone,
  Seat,
  SeatType,
  Zone,
  ZonePrice,
} from '@Core/@types/skyway'

// Whitelist of web modes of sale to filter out those we aren't
// interested in before they hit the UI
export const WEB_MOS_REFS: string[] = [
  '5',
  '7',
  '10',
  '27',
  '33',
  '36',
  '37',
  '39',
  '42',
]

export interface BestAvailableSelection {
  price_type_ref?: any
  instance_ref?: any
  qty?: any
  zone_ref?: any
  special_requests?: string
}
export interface Recipient {
  first_name: String
  last_name: String
  email: String
}
export type ZonePriceWithScreen<T> = Partial<T> & {
  screen_ref: string
  price_letter: string
  color: number
}

export interface State {
  key: string | null
  seat_map_svg: string | null
  seat_map_ref?: string
  seat_map_title?: string
  screens: Screen[]
  screen_meta: Screen[]
  zones: Zone[]
  chosen_screen?: string
  chosen_zone?: string
  screen_availability: Screen[]
  available_seats: Seat[]
  best_prices: ZonePriceWithScreen<ZonePrice>[]
  unique_prices: ZonePrice[]
  screen_zones: ScreenZone[]
  chosen_screen_zone?: ScreenZone
  selection: any[]
  no_consecutive_availability: boolean
  selection_start: any
  best_available_selection: BestAvailableSelection[]
  best_available_zone?: Zone
  general_admission_zones?: Zone[]
  wheelchair: boolean
  recipients: { [key: string]: Recipient[] }
  active: object | null
  maps: {}
  types: SeatType[]
  updating: boolean
  filters: string[]
  access_filters: string[]
  seats: Seat[]
  web_mos_refs: string[]
}

/**
 * Initial state
 */
const state = (): State => ({
  key: null,
  seat_map_ref: undefined,
  seat_map_title: undefined,
  seat_map_svg: null,
  screens: [],
  screen_meta: [],
  zones: [],
  chosen_screen: undefined,
  chosen_zone: undefined,
  chosen_screen_zone: undefined,
  screen_availability: [],
  available_seats: [],
  best_prices: [],
  unique_prices: [],
  screen_zones: [],
  selection: [],
  selection_start:
    null /** @todo - set timestamp and use getter to expire selection */,
  no_consecutive_availability: false,
  best_available_selection: [],
  best_available_zone: undefined,
  general_admission_zones: undefined,
  wheelchair: false,
  recipients: {},
  active: null,
  maps: {},
  types: [],
  updating: false,
  filters: [],
  access_filters: [],
  seats: [],
  web_mos_refs: WEB_MOS_REFS,
})

export default state
