import { ActionTree, ActionContext } from 'vuex'
import { State } from './state'
import { RootState } from '@Core/store/types'
import { ScreenZone, Seat, SeatMap, SeatType, Zone } from '@Core/@types/skyway'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getSeatMap(
    context: ActionContext<State, RootState>,
    instance_ref: string
  ): Promise<SeatMap> {
    const data = await this.$seats.getSeatMap(instance_ref)

    context.commit(types.SET_SCREENS, data.getSeatMap)
    context.commit(types.SET_MAP_SVG, data.getSeatMap)

    return data.getSeatMap
  },

  async getSeats(
    context: ActionContext<State, RootState>,
    instance_ref: string
  ): Promise<SeatMap> {
    const data = await this.$seats.getSeatMap(instance_ref)

    context.commit(types.SET_SEATS, data.getSeatMap)

    return data.getSeatMap
  },

  async getSeatMapMeta(
    context: ActionContext<State, RootState>,
    instance_ref: string
  ): Promise<SeatMap> {
    const data = await this.$seats.getSeatMapMeta(instance_ref)

    context.commit(types.SET_MAP_META, data.getSeatMap)
    if (data.getSeatMap.screens) {
      context.commit(types.SET_SCREEN_META, data.getSeatMap)
    }

    return data.getSeatMap
  },

  async getSeatMapZones(
    context: ActionContext<State, RootState>,
    instance_ref: string
  ): Promise<any> {
    const data = await this.$seats.getSeatMapZones(instance_ref)

    context.commit(types.SET_ZONES, data.getSeatMap)
    context.commit(types.SET_BEST_PRICES, data.getSeatMap)
    context.commit(types.SET_UNIQUE_PRICES, data.getSeatMap)

    return data.getSeatMap
  },

  /**
   * Get the seat availability,
   * only returns available seats, we match this against the map
   * to show available seats
   */
  async getSeatAvailability(
    context: ActionContext<State, RootState>,
    args: { instance_ref: string; screens?: string[] }
  ): Promise<Array<Seat>> {
    context.commit(types.SET_UPDATING, true)
    const data = await this.$seats.getSeatAvailability(
      args.instance_ref,
      args.screens
    )

    const getSeatAvailability = data.getSeatAvailability

    context.commit(types.SET_AVAILABILITY, getSeatAvailability)
    context.commit(types.SET_UPDATING, false)
    return data.getSeatAvailability
  },

  /**
   * Get a list of screens with availability and price range
   */
  async getScreenAvailability(
    context: ActionContext<State, RootState>,
    instance_ref: string
  ): Promise<Array<Screen>> {
    const data = await this.$seats.getScreenAvailability(instance_ref)

    context.commit(types.SET_SCREEN_AVAILABILITY, data.getScreenAvailability)
    return data.getScreenAvailability
  },

  async getScreenZones(
    context: ActionContext<State, RootState>,
    { instance_ref, facility_ref }: any
  ): Promise<any> {
    const data = await this.$seats.getScreenZones(instance_ref, facility_ref)

    context.commit(types.SET_SCREEN_ZONES, data.getScreenZones)

    return data.getScreenZones
  },

  /**
   * Find a valid zone with the highest availability and
   * valid prices then commit to state
   * This will be available at step 1 of the best available journey
   * to prevent the user selecting too many tickets
   */
  async selectBestZone(
    context: ActionContext<State, RootState>,
    screenZones: ScreenZone[]
  ): Promise<Zone | false> {
    const zones: Zone[] = screenZones.reduce((acc: Zone[], sz: ScreenZone) => {
      if (sz.zones && sz.zones.length > 0) {
        for (const zone of sz.zones) {
          zone && acc.push(zone)
        }
      }

      return acc
    }, [])

    const selectedZone = new Promise<Zone | false>((resolve) => {
      const possible = zones.filter(
        (z) =>
          z.availability &&
          z.availability > 0 &&
          z.prices &&
          z.prices.filter((zp) => zp && zp.enabled).length > 0
      )

      possible &&
        possible.sort((a, b) =>
          Number(a.availability) > Number(b.availability) ? -1 : +1
        )

      if (possible[0]) {
        context.commit(types.SET_BEST_AVAILABLE_ZONE, possible[0])
      }

      return resolve(possible[0] || false)
    })

    return selectedZone
  },

  async getSeatTypes(
    context: ActionContext<State, RootState>
  ): Promise<SeatType[]> {
    if (!context.state.types) {
      const data = await this.$seats.getSeatTypes()

      context.commit(types.SET_SEAT_TYPES, data.getSeatTypes)
      return data.getSeatTypes
    } else {
      return context.state.types
    }
  },
}

export default actions
