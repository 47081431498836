export const types = {
  SET_MAP_KEY: 'SET_MAP_KEY',
  SET_MAP_SVG: 'SET_MAP_SVG',
  SET_MAP_META: 'SET_MAP_META',
  SET_SCREENS: 'SET_SCREENS',
  SET_SCREEN_META: 'SET_SCREEN_META',
  SET_SCREEN_ZONES: 'SET_SCREEN_ZONES',
  SET_ZONES: 'SET_ZONES',
  SET_CHOSEN_SCREEN: 'SET_CHOSEN_SCREEN',
  SET_CHOSEN_ZONE: 'SET_CHOSEN_ZONE',
  SET_CHOSEN_SCREEN_ZONE: 'SET_CHOSEN_SCREEN_ZONE',
  SET_AVAILABILITY: 'SET_AVAILABILITY',
  SET_SCREEN_AVAILABILITY: 'SET_SCREEN_AVAILABILITY',
  SET_BEST_PRICES: 'SET_BEST_PRICES',
  SET_UNIQUE_PRICES: 'SET_UNIQUE_PRICES',
  SET_SECTIONS: 'SET_SECTIONS',
  SET_SELECTION: 'SET_SELECTION',
  SET_SEAT_TYPES: 'SET_SEAT_TYPES',
  SET_SEATS: 'SET_SEATS',
  SET_UPDATING: 'SET_UPDATING',
  UPDATE_MAP: 'UPDATE_MAP',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  REMOVE_SELECTION: 'REMOVE_SELECTION',
  UPDATE_PRICE_FILTERS: 'UPDATE_PRICE_FILTERS',
  UPDATE_ACCESS_FILTERS: 'UPDATE_ACCESS_FILTERS',
  SET_ACTIVE: 'SET_ACTIVE',
  CLEAR_ACTIVE: 'CLEAR_ACTIVE',
  CLEAR_BEST_AVAILABLE_SELECTION: 'CLEAR_BEST_AVAILABLE_SELECTION',
  SET_BEST_AVAILABLE_SELECTION: 'SET_BEST_AVAILABLE_SELECTION',
  SET_BEST_AVAILABLE_ZONE: 'SET_BEST_AVAILABLE_ZONE',
  SET_RECIPIENT: 'SET_RECIPIENT',
  SET_RECIPIENTS: 'SET_RECIPIENTS',
  CLEAR_RECIPIENTS: 'CLEAR_RECIPIENTS',
  CLEAR_INSTANCE_RECIPIENTS: 'CLEAR_INSTANCE_RECIPIENTS',
  REMOVE_RECIPIENT: 'REMOVE_RECIPIENT',
  TOGGLE_WHEELCHAIR: 'TOGGLE_WHEELCHAIR',
  SET_CONSECUTIVE_AVAILABILTY: 'SET_CONSECUTIVE_AVAILABILTY',
  SET_GENERAL_ADMISSION_ZONES: 'SET_GENERAL_ADMISSION_ZONES',
}
