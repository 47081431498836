export const Pages = {
  Calendar: () =>
    import('@Tickets/pages/calendar/_id').then((m) => m.default || m),
  SelectArea: () =>
    import('@Tickets/pages/select-area/_id').then((m) => m.default || m),
  SelectSeats: () =>
    import('@Tickets/pages/select-seats/_id').then((m) => m.default || m),
  BestAvailable: () =>
    import('@Tickets/pages/best-available/_id').then((m) => m.default || m),
  BestAvailableStepOne: () =>
    import('@Tickets/pages/best-available/steps/step-one').then(
      (m) => m.default || m
    ),
  BestAvailableStepTwo: () =>
    import('@Tickets/pages/best-available/steps/step-two').then(
      (m) => m.default || m
    ),
  GeneralAdmission: () =>
    import('@Tickets/pages/general-admission/_id').then((m) => m.default || m),
  GeneralAdmissionStepOne: () =>
    import('@Tickets/pages/general-admission/steps/step-one').then(
      (m) => m.default || m
    ),
  TimedEntry: () =>
    import('@Tickets/pages/timed-entry/_event_ref/index').then(
      (m) => m.default || m
    ),
  TimedEntryDate: () =>
    import('@Tickets/pages/timed-entry/_event_ref/_instance_ref/index').then(
      (m) => m.default || m
    ),
  TimedEntryDateTime: () =>
    import(
      '@Tickets/pages/timed-entry/_event_ref/_instance_ref/_zone_ref/index'
    ).then((m) => m.default || m),
}
