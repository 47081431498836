import { GetterTree } from 'vuex'
import { RootState } from '@Core/store/types'
import { ZonePrice, Seat, ScreenZone, Zone } from '@Core/@types/skyway'
import { flatten } from 'lodash'
import { State } from './state'
import { buildDynamicQuery } from '@Core/helpers/filters'

const getters: GetterTree<State, RootState> = {
  /**
   * Get a list of seats by screen_ref
   */
  seats: (state: State) => (screen_ref: number | string) => {
    const screen = state.screens
      ? state.screens.find((s) => s.screen_ref == screen_ref)
      : false

    if (screen && screen.seats) {
      return screen.seats
    } else {
      return []
    }
  },

  selectionCount: (state: State) => {
    return state.best_available_selection.reduce((total, type) => {
      return total + type.qty
    }, 0)
  },

  selectionByPriceType: (state: State) => (price_type_ref: number | string) => {
    return (
      state.best_available_selection.find(
        (s) => s.price_type_ref === Number(price_type_ref)
      )?.qty || 0
    )
  },

  /**
   * If we have to use string matching to get screenZones
   * then we can use this convenience getter to remove
   * boilerplate from component code
   */
  screenZonesFilteredByTitle:
    (state: State) =>
    (filters: string[], operator: 'OR' | 'AND' = 'OR'): ScreenZone[] => {
      const filterFunctions = buildDynamicQuery(filters, 'screen_title')

      return state.screen_zones && operator === 'AND'
        ? state.screen_zones.filter((sz) => filterFunctions.every((f) => f(sz)))
        : state.screen_zones.filter((sz) => filterFunctions.some((f) => f(sz)))
    },

  allSeats: (state: State) => {
    return flatten(state.screens.map((s) => s.seats))
  },

  selectedSeatRefs: (state: State) => {
    return state.selection.map((s) => s.seat.seat_ref)
  },

  hasStanding: (state: State): boolean => {
    return Boolean(
      state.screen_meta &&
        state.screen_meta.find(
          (screen) => screen.seating_configuration === 'standing'
        )
    )
  },

  wheelchairZonesWithAvailability: (state: State) => {
    const wheelchairScreenZones = state.screen_zones.filter(
      (sz) => sz.wheelchair_availability && sz.wheelchair_availability > 0
    )
    let result: Zone[] = []
    if (wheelchairScreenZones) {
      for (const screenZone of wheelchairScreenZones) {
        const zones =
          (screenZone.zones &&
            screenZone.zones.filter((z) =>
              z?.prices?.some((p) =>
                p?.price_title?.toLowerCase().includes('wheelchair')
              )
            )) ||
          []
        for (const zone of zones) {
          zone && result.push(zone)
        }
      }
    }

    return result
  },

  /**
   * Get all zones that should be considered when getting
   * general admission price types
   */
  generalAdmissionZones: (state: State) => (hasSeatmap: boolean) => {
    const refs: string[] =
      state.screen_meta &&
      state.screen_meta.reduce((acc: string[], scr) => {
        if (hasSeatmap) {
          if (scr.seating_configuration === 'standing') {
            acc.push(scr.screen_ref)
          }
        } else {
          acc.push(scr.screen_ref)
        }

        return acc
      }, [])

    return (
      state.screen_zones &&
      state.screen_zones.reduce((acc: Zone[], sc) => {
        if (refs.includes(sc.screen_ref) && sc.zones) {
          sc.zones.forEach((z) => {
            z && acc.push(z)
          })
        }
        return acc
      }, [])
    )
  },

  /**
   * Get the standing/seated zone with the most availability
   */
  bestZoneByType: (state: State) => (type: string) => {
    if (state.screen_meta && state.screen_zones) {
      const refs: string[] = state.screen_meta.reduce((acc: string[], scr) => {
        if (scr && scr.seating_configuration === type) {
          acc.push(scr.screen_ref)
        }

        return acc
      }, [])

      const zones: Zone[] = state.screen_zones.reduce((acc: Zone[], sc) => {
        if (refs.includes(sc.screen_ref) && sc.zones) {
          sc.zones.forEach((z) => {
            z && acc.push(z)
          })
        }
        return acc
      }, [])

      if (zones && zones.length > 0) {
        return zones.sort((a, b) => {
          if (Number(a.availability) < Number(b.availability)) return 1
          if (Number(a.availability) > Number(b.availability)) return -1
          return 0
        })[0]
      }
    }
    return null
  },

  possibleTypes: (state: State) => {
    const types: string[] = []

    state.screens.forEach((screen) => {
      const result: string[] =
        screen && screen.seats
          ? screen.seats.map((s) =>
              s && parseInt(s?.status_ref) === 0 ? (s?.type as string) : null
            )
          : []
      types.push(result.filter((r) => r))
    })

    return [...new Set(flatten(types))]
  },

  allocationTypes: (state: State) => {
    const allocations: string[] = []

    state.screens.forEach((screen) => {
      const result: string[] =
        screen && screen.seats
          ? screen.seats.map((s) =>
              parseInt(s?.status_ref) === 0 ? s?.allocation_ref : null
            )
          : []
      allocations.push(result.filter((r) => r))
    })

    return [...new Set(flatten(allocations))]
  },

  seatMapByScreen: (state: State) => (screen_ref: number | string) => {
    return state.maps[screen_ref] || []
  },

  /**
   * Get a list of available seats by screen ref
   */
  available: (state: State) => (screen_ref: number) => {
    const seats = state.available_seats
      ? state.available_seats.filter(
          (s) => parseInt(s.screen_ref) === parseInt(screen_ref)
        )
      : false
    return seats || []
  },

  isSoldOut: (state: State): boolean => {
    const { screen_zones } = state
    const sumOfAvailability = screen_zones.reduce((availability, zone) => {
      if (zone.availability) {
        return availability + zone.availability
      }

      return availability
    }, 0)

    return sumOfAvailability === 0
  },

  getPricesByZoneRef: (state: State) => (zone_ref: number) => {
    const prices: ZonePrice[] = []
    const zones = state.zones
    if (zones && zones.length) {
      zones.forEach((zone) => {
        if (zone.prices && zone.prices.length) {
          zone.prices.forEach((zonePrice) => {
            if (
              zonePrice &&
              parseInt(zonePrice.zone_ref) === parseInt(zone_ref)
            ) {
              prices.push(zonePrice)
            }
          })
        }
      })
    }
    return prices
  },

  configurations: (state: State): string[] => {
    const options: string[] = []
    if (state.screen_meta) {
      for (const s of state.screen_meta) {
        if (
          s.seating_configuration &&
          !options.includes(s.seating_configuration)
        ) {
          options.push(s.seating_configuration)
        }
      }
    }

    return options
  },

  mapMetaData: (
    state: State
  ): {
    seat_map_svg: string | null
    seat_map_title?: string
    seat_map_ref?: string
  } => {
    return {
      seat_map_ref: state.seat_map_ref,
      seat_map_title: state.seat_map_title,
      seat_map_svg: state.seat_map_svg,
    }
  },

  zoneBySeat:
    (state: State) =>
    (seat: Seat): Zone | false => {
      if (state.zones) {
        return state?.zones?.find((z) => z.zone_ref === seat.zone_ref) || false
      }

      return false
    },
}

export default getters
