import { Pages } from '@Tickets/config/imports'

export const ROUTE_NAME = {
  CALENDAR: 'calendar-id',
  SELECT_SEATS: 'select-seats',
  SELECT_AREA: 'select-area',
  BEST_AVAILABLE: 'best-available',
  GENERAL_ADMISSION: 'general-admission',
  TIMED_ENTRY: 'timed-entry',
  TIMED_ENTRY_DATE: 'timed-entry-date',
  TIMED_ENTRY_DATE_TIME: 'timed-entry-date-time',
}

export default [
  {
    path: '/calendar/:id?',
    component: Pages.Calendar,
    name: ROUTE_NAME.CALENDAR,
  },
  {
    path: '/select-seats/:id?',
    component: Pages.SelectSeats,
    name: ROUTE_NAME.SELECT_SEATS,
  },
  {
    path: '/select-area/:id?',
    component: Pages.SelectArea,
    name: ROUTE_NAME.SELECT_AREA,
  },
  {
    path: '/best-available/:id?',
    component: Pages.BestAvailable,
    children: [
      {
        path: '',
        component: Pages.BestAvailableStepOne,
        name: 'best-available-step-1',
      },
      {
        path: 'your-tickets',
        component: Pages.BestAvailableStepTwo,
        name: 'best-available-step-2',
      },
    ],
  },
  {
    path: '/general-admission/:id?',
    component: Pages.GeneralAdmission,
    children: [
      {
        path: '',
        component: Pages.GeneralAdmissionStepOne,
        name: ROUTE_NAME.GENERAL_ADMISSION,
      },
    ],
  },
  {
    path: '/timed-entry/:event_ref?',
    component: Pages.TimedEntry,
    name: ROUTE_NAME.TIMED_ENTRY,
  },
  {
    path: '/timed-entry/:event_ref/:instance_ref',
    component: Pages.TimedEntryDate,
    name: ROUTE_NAME.TIMED_ENTRY_DATE,
  },
  {
    path: '/timed-entry/:event_ref/:instance_ref/:zone_ref',
    component: Pages.TimedEntryDateTime,
    name: ROUTE_NAME.TIMED_ENTRY_DATE_TIME,
  },
]
